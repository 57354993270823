import style from './Main.module.scss';

const MainPage = () => (
  <div className={style.self}>
    <div className={style.title}>
      Website is building...
    </div>
  </div>
);

export default MainPage;