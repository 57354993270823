import { Navigate, Route, Routes } from "react-router";
import MainPage from "./page/Main";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<MainPage />} />
    <Route path="/*" element={<Navigate to="/"/>} />
  </Routes>
);

export default AppRoutes;